import { AppBar, Toolbar, IconButton, Button, Typography, FormGroup, FormControlLabel, Switch } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { signOut } from '../Auth/firebase'
import GlobalContext from '../Context/GlobalContext'
import { useContext } from 'react'
import Brightness6Icon from '@mui/icons-material/Brightness6';
import WaLogo from '../../assets/img/wa-logo.png'
import { Box, maxHeight } from '@mui/system'

function Header() {
    const { displayMode, setDisplayMode } = useContext(GlobalContext)

    const handleChangeDisplayMode = (e) => {
        console.log(e.target.value)
        if(e.target.checked === true) {
            setDisplayMode('dark')
            localStorage.setItem('displayMode', 'dark')
        } else {
            setDisplayMode('light')
            localStorage.setItem('displayMode', 'light')
        }
    }

    return (
        <AppBar position="static" sx={displayMode === 'light' ? {
            backgroundColor: '#fff', 
            color: '#333'
            } : {}}>
            <Toolbar>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>
            <Box sx={{flexGrow: 1}}>
                <img src={WaLogo} style={{maxHeight: '30px', marginTop: '10px'}} />
            </Box>
            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                WebAlert
            </Typography> */}
            <FormControlLabel control={<Switch onChange={handleChangeDisplayMode} checked={displayMode === 'light' ? false : true} />} label={<Brightness6Icon sx={{ mt:1 }} />} />
            <Button color="inherit" onClick={() => signOut()}>Logout</Button>
            </Toolbar>
        </AppBar>
    )
}

export default Header