import { Modal, Box, Typography, TextField, Button, FormControl, Select, InputLabel, MenuItem, FormHelperText } from '@mui/material'
import { useEffect, useState } from 'react';
import { db } from '../Auth/firebase'
import { collection, doc, addDoc, updateDoc } from "firebase/firestore";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxWidth: '100%',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

function AddAlertModal({alertModalData, setAlertModalData, currentUser, setUpdateAlerts}) {
    const [ type, setType ] = useState('price')
    const [ title, setTitle ] = useState('')
    const [ url, setUrl ] = useState('')
    const [ user, setUser ] = useState(currentUser)
    const [ period, setPeriod ] = useState(24);

    /**
     * Set default value (UseState does not seems to be populated on component draw)
     */
    useEffect(() => {
        if(alertModalData) {
            console.log(alertModalData)
            if(alertModalData.title) setTitle(alertModalData.title)
            if(alertModalData.url) setUrl(alertModalData.url)
            if(alertModalData.period) setPeriod(alertModalData.period)
            if(alertModalData.type) setType(alertModalData.type)
        } else {
            setTitle('')
            setUrl('')
            setPeriod(24)
            setType('price')
        }
    }, [alertModalData])

    const handleClose = () => {
        setAlertModalData(false)
    }

    const handleAddAlert =  async () => {
        if(alertModalData.id) {
            const updatedDoc = {
                title: title,
                url: url,
                period: period,
                updateDate: new Date()
            }
            await updateDoc(doc(db, `users/${user.uid}/alerts/${alertModalData.id}`), updatedDoc)
        } else {
            await addDoc(collection(db, `users/${user.uid}/alerts`), {
                title: title,
                url: url,
                status: 'initial',
                period: period,
                date: new Date()
            })
        }
        setAlertModalData(false)
        setUpdateAlerts(new Date())
    }

    return (
        <Modal open={alertModalData !== false ? true : false} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <Typography variant="h5">{alertModalData && alertModalData.id ? "Update an alert" : "Add new alert"}</Typography>
                <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" fullWidth margin="dense" value={type} onChange={(e) => setPeriod(e.target.value)} label="Age">
                        <MenuItem value={'price'}>Price</MenuItem>
                        <MenuItem disabled value={''}>Ping&nbsp;<small>(coming soon)</small></MenuItem>
                        <MenuItem disabled value={''}>Content&nbsp;<small>(coming soon)</small></MenuItem>
                    </Select>
                    <FormHelperText>{type === "price" ? (
                        "Be warned each time product price changes"
                    ) : null}</FormHelperText>
                </FormControl>
                <FormControl>
                    <TextField id="title" value={title} label="Alert title" fullWidth margin="dense" variant="standard" onChange={(e) => setTitle(e.target.value)} />
                    <FormHelperText>Give name to your alert</FormHelperText>
                </FormControl>
                <FormControl>
                    <TextField id="url" value={url} label="Url" fullWidth margin="dense" variant="standard" onChange={(e) => setUrl(e.target.value)} />
                    <FormHelperText>Paste product your want to check</FormHelperText>
                </FormControl>
                <FormControl variant="standard">
                    <InputLabel id="demo-simple-select-standard-label">Periodicity</InputLabel>
                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" fullWidth margin="dense" value={period} onChange={(e) => setPeriod(e.target.value)} label="Age">
                        <MenuItem value={24}>Every day</MenuItem>
                        <MenuItem value={12}>Twice a day</MenuItem>
                        <MenuItem value={1}>Every Hour</MenuItem>
                    </Select>
                    <FormHelperText>Set periodicity of checks</FormHelperText>
                </FormControl>
                <div mt={4}>
                    <Button fullWidth sx={{ mt: 1 }} variant="outlined" color="secondary" onClick={handleAddAlert}>
                        {alertModalData && alertModalData.id ? "Update alert" : "Add alert"}
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default AddAlertModal