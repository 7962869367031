import { 
    Card, 
    CardContent, 
    CardHeader, 
    Typography, 
    List, 
    ListItem, 
    ListItemAvatar, 
    ListItemText, 
    IconButton, 
    Avatar, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    Button
} from '@mui/material'
import { collection, doc, getDocs, deleteDoc, updateDoc, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from 'react';
import { parseDomain, ParseResultType } from "parse-domain";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import { db } from '../Auth/firebase'
import { statuses } from '../Utils/definitions'
import AddAlertModal from './AddAlertModal';
import { Link } from 'react-router-dom';
import GlobalContext from '../Context/GlobalContext';

function AlertList({currentUser}) {
    const { displayMode } = useContext(GlobalContext)
    const navigate = useNavigate();
    const [ alerts, setAlerts ] = useState([])
    const [ alertModalData, setAlertModalData ] = useState(false)
    const [ deleteAlertIsOpen, setDeleteAlertIsOpen ] = useState(false)
    const [ updateAlerts, setUpdateAlerts ] = useState(new Date())

    useEffect(() => {
        let tmpAlerts = []
        const q = query(collection(db, `alerts`), where('owner', '==', currentUser.uid))
        // const alertsSnap = await getDocs(collection(db, `users/${currentUser.uid}/alerts`))
        getDocs(q).then(alertsSnap => {
            alertsSnap.forEach(alert => {
                const parsedUrl = new URL(alert.get('url'));
                parsedUrl.pathname = "/"
                parsedUrl.searchParams.forEach((searchParam, k) => {
                    parsedUrl.searchParams.delete(k)
                })
    
                tmpAlerts.push({
                    id: alert.id,
                    name: alert.get('name'),
                    title: alert.get('title'),
                    status: alert.get('status'),
                    url: alert.get('url'),
                    period: alert.get('period'),
                    type: alert.get('type'),
                    icon: `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${parsedUrl.origin}&size=64`,
                    hostname: parsedUrl.hostname,
                    domain: parseDomain(parsedUrl.hostname).domain
                })
            })
            setAlerts(tmpAlerts)
        }).catch(error => {
            console.log(error)
        })
    }, [updateAlerts])

    const deleteAlert = async (id) => {
        const deletedDoc = await deleteDoc(doc(db, `alerts/${id}`))
        setDeleteAlertIsOpen(false)
        setUpdateAlerts(new Date())
    }

    const handleDeleteAlert = async (alert) => {
        setDeleteAlertIsOpen(alert)
    }
    const pauseAlert = async (id) => {
        const deletedDoc = await updateDoc(doc(db, `alerts/${id}`), {status: 'paused'})
        setUpdateAlerts(new Date())
    }
    const resumeAlert = async (id) => {
        const deletedDoc = await updateDoc(doc(db, `alerts/${id}`), {status: 'initial'})
        setUpdateAlerts(new Date())
    }

    return (
        <Card elevation={2}>
            <CardContent>
                <CardHeader title={
                    <Typography variant="h5" style={{ textTransform: 'uppercase' }}>Your alerts</Typography>
                } action={
                    <>
                        {alerts.length < 5 ? (
                            <IconButton aria-label="settings" onClick={() => navigate('/alert/add')}>
                                <AddCircleIcon sx={{ fontSize: 40 }} color="secondary" />
                            </IconButton>
                        ) : null}
                    </>
                } />
                {alerts.length == 0 ? (
                    <Typography>You don't have any alert configured yet !</Typography>
                ) : null}
                <List dense={false}>
                    {alerts.map(alert => (
                        <ListItem key={alert.id} secondaryAction={
                            <>
                                <IconButton edge="end" aria-label="edit" onClick={() => navigate(`/alert/${alert.id}`)}>
                                    <EditIcon />
                                </IconButton>
                                {alert.status == "paused" ? (
                                    <IconButton edge="end" aria-label="delete" onClick={() => resumeAlert(alert.id)}>
                                        <PlayArrowIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton edge="end" aria-label="delete" onClick={() => pauseAlert(alert.id)}>
                                        <PauseIcon />
                                    </IconButton>
                                )}
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteAlert(alert)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }>
                            <ListItemAvatar>
                                <Avatar sx={{backgroundColor: '#fff'}} alt={alert.domain} src={alert.icon}>{alert.domain.charAt(0).toUpperCase()}</Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<a href={alert.url} style={{
                                    textDecoration: 'none',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: displayMode == 'light' ? '#333' : '#fff'
                                }} target="_blank"><LinkIcon sx={{ mr: 1 }} /> <span>{alert.title}</span></a>}
                                secondary={<small>Status: {statuses[alert.status]}</small>}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
            <AddAlertModal alertModalData={alertModalData} setAlertModalData={setAlertModalData} currentUser={currentUser} setUpdateAlerts={setUpdateAlerts} />
            <Dialog
                open={deleteAlertIsOpen !== false ? true : false}
                onClose={() => setDeleteAlertIsOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Are you sure you want to delete alert ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Alert <b>"{deleteAlertIsOpen.title}"</b> will be deleted from database
                        and will never be fired again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setDeleteAlertIsOpen(false)}>Cancel</Button>
                    <Button color="secondary" onClick={() => deleteAlert(deleteAlertIsOpen.id)} autoFocus>Delete!</Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}

export default AlertList