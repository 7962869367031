import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home"
import Dashboard from "./Components/Dashboard/Dashboard"
import AlertForms from './Components/Dashboard/AlertForms'
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { AuthProvider  } from "./Components/Auth/AuthContext"
import PrivateRoute from "./Components/Utils/PrivateRoute"
import { CssBaseline } from "@mui/material";
import GlobalContext from "./Components/Context/GlobalContext";


import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  const [displayMode, setDisplayMode] = useState('light')

  useEffect(() => {
    if(localStorage.getItem('displayMode')) setDisplayMode(localStorage.getItem('displayMode'))
  }, [displayMode])

  const Themes = {
    darkTheme: createTheme({
      palette: {
        mode: 'dark',
        primary: {
          main: "#fff"
        },
        secondary: {
          main: '#f50057',
        },
      },
      components: {
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: '1em'
            }
          }
        },
        MuiTextField: {
          defaultProps: {
            variant: 'outlined',
          },
          styleOverrides: {
            root: {
              fontSize: '2em'
            }
          }
        }
      }
    }),
    lightTheme: createTheme({
      palette: {
        mode: 'light',
        secondary: {
          main: '#f50057',
        }
      },
      components: {
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: '1em'
            }
          }
        },
        MuiLink: {
          styleOverrides: {
            root: {
              color: '#333'
            }
          }
        }
      }
    })
  }

  return (
    <GlobalContext.Provider value={{displayMode, setDisplayMode}}>
      <ThemeProvider theme={displayMode === "light" ? Themes.lightTheme : Themes.darkTheme}>
        <CssBaseline />
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<PrivateRoute Component={Dashboard} />} />
              <Route path="/alert/:alertid" element={<PrivateRoute Component={AlertForms} />} />
            </Routes>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </GlobalContext.Provider>
  );
}

export default App;
