import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { firebaseApp  } from "./firebase";
import { Box } from "@mui/material";
import Logo from '../../assets/img/wa-logo.png'

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const auth = getAuth(firebaseApp)
    auth.onAuthStateChanged((user) => {
        setCurrentUser(user)
        setPending(false)
    });
  }, []);

  if(pending){
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%'}}>
        <img alt="logo" src={Logo} style={{maxWidth: '90%'}} />
      </Box>
    )
  }

  return (
    <AuthContext.Provider value={{ currentUser }} >
      {children}
    </AuthContext.Provider>
  );
};