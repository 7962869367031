import { Container, Grid } from "@mui/material"
import { useEffect } from "react"
import { doc, getDoc, addDoc, setDoc } from "firebase/firestore";
import { db } from "../Auth/firebase";
import Header from "../Layout/Header"
import AlertList from "./AlertList"

function Dashboard({currentUser}) {
    useEffect(() => {
        // Create user if not exists
        const docRef = doc(db, "users", currentUser.uid);
        const userDoc = {
            role: "user",
            email: currentUser.email
        }
        getDoc(docRef).then(docSnap => {
            if(!docSnap.exists()) {
                setDoc(doc(db, "users", currentUser.uid), userDoc)
                console.log('User added')
            }
        }).catch(error => {
            setDoc(doc(db, "users", currentUser.uid), userDoc)
            console.log('User added')
        });
    }, [])

    return (
        <>
            <Header />
            <Container maxWidth="100%" sx={{ mt: 3 }}>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <AlertList currentUser={currentUser} />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default Dashboard