import { Box, Grid, Typography } from "@mui/material"
import { useState } from "react"
import Login from "../Auth/Login"
import Register from "../Auth/Register"
import Logo from '../../assets/img/wa-logo.png'

function Home() {
    const [ mode, setMode ] = useState('login')

    return (
        <Box>
            <Grid container>
                <Grid item sm={12} sx={{
                    //backgroundImage: `url(${waJumbo})`, 
                    backgroundColor: '#212121',
                    backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height: '90vh', color: 'white', p: 2}}>
                    <Grid container sx={{height: '100%'}}>
                        <Grid item sm={12} md={6} sx={{width: '100%'}}>
                            <Grid container direction="column" justifyContent='center' alignItems='center' sx={{height: '100%', width: '100%'}}>
                                <Grid item sx={{textAlign: 'center'}}>
                                    <img alt="logo" src={Logo} style={{maxWidth: '200px'}} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5">Be warned if it changes !</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                {mode === 'login' ? <Login setMode={setMode} /> : <Register setMode={setMode} />}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Home