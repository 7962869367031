import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { registerWithEmailAndPassword, } from './firebase'
import { Card, CardContent, TextField, Button, Box, Typography, Alert } from "@mui/material";

function Register({setMode}) {
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ loginError, setLoginError ] = useState(false)
    const navigate = useNavigate();

    const handleRegisterWithEmailAndPassword = (e) => {
        e.preventDefault()
        registerWithEmailAndPassword(email, password).then(result => {
            navigate('/dashboard')
        }).catch(error => {
            setLoginError('An error occured while trying to create your account. Please try again')
            console.log(error)
        })
    }

    return (
        <Card elevation={4}>
            <CardContent>
                <form onSubmit={handleRegisterWithEmailAndPassword}>
                    <Box sx={{textAlign: 'center'}}>
                        <Typography variant="h4">Registration</Typography>
                    </Box>
                    <TextField fullWidth margin="dense" id="standard-basic" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                    <TextField type="password" fullWidth margin="dense" id="standard-basic" label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} />
                    <div mt={4}>
                        <Button type="submit" fullWidth color="secondary" sx={{ mt: 1 }} variant="contained" onClick={handleRegisterWithEmailAndPassword}>Register with email & password</Button>
                    </div>
                    <Button  color="secondary" onClick={() => setMode('login')}><small>Already have an account ?</small></Button>
                    {loginError ? <Alert severity="error">{loginError}</Alert> : null}
                </form>
            </CardContent>
        </Card>
    )
}
export default Register;