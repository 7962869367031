import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginWithEmailAndPassword } from './firebase'
import { Card, CardContent, TextField, Button, Typography, Box, Alert } from "@mui/material";

function Login({setMode}) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [ loginError, setLoginError ] = useState(false)
    const navigate = useNavigate();

    const handleLoginWithEmailAndPassword = (e) => {
        e.preventDefault()
        loginWithEmailAndPassword(email, password).then((result) => {
            navigate('/dashboard')
        }).catch(error => {
            setLoginError('An error occured while trying to identify your account. Please try again')
            console.log(error)
        })
    }

    return (
        <Card elevation={4}>
            <CardContent>
                <form onSubmit={handleLoginWithEmailAndPassword}>
                    <Box sx={{textAlign: 'center'}}>
                        <Typography variant="h4">Identification</Typography>
                    </Box>
                    <TextField fullWidth margin="dense" id="email" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                    <TextField type="password" fullWidth margin="dense" id="password" label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} />
                    <div mt={4}>
                        <Button type="submit" fullWidth sx={{ mt: 1 }} variant="contained" color="secondary" onClick={handleLoginWithEmailAndPassword}>Login with email & password</Button>
                    </div>
                    <Button color="secondary"  onClick={() => setMode('register')}><small>Need to create an account ?</small></Button>
                    {loginError ? <Alert severity="error">{loginError}</Alert> : null}
                </form>
            </CardContent>
        </Card>
    )
}
export default Login;