// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
const { getFirestore, Timestamp, FieldValue } = require('firebase/firestore');

const firebaseConfig = {
  apiKey: "AIzaSyCcwIUU8RRX-w8Y_Wt6LcNoSHd7oIOcO1E",
  authDomain: "react-webalert.firebaseapp.com",
  projectId: "react-webalert",
  storageBucket: "react-webalert.appspot.com",
  messagingSenderId: "885462765493",
  appId: "1:885462765493:web:4bb71d318bcd183da893fc",
  measurementId: "G-PSWDN3PLM8"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore();

const registerWithEmailAndPassword = (email, password) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth(firebaseApp);
    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      resolve(true);
    })
    .catch((error) => {
      const errorCode = error.code;
      reject(errorCode)
    });
  })
}

const signOut = () => {
  const auth = getAuth(firebaseApp);
  auth.signOut()
}

const loginWithEmailAndPassword = (email, password) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth(firebaseApp);
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      resolve(true);
    })
    .catch((error) => {
      const errorCode = error.code;
      reject(errorCode)
    });
  });
}

export {
  firebaseApp,
  db,
  signOut,
  registerWithEmailAndPassword,
  loginWithEmailAndPassword
}