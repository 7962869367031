import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";

function PrivateRoute({ Component }) {
    const {currentUser} = useContext(AuthContext);
    if(currentUser)
        return <Component currentUser={currentUser} />
    else
        return <Navigate to="/" />
  }
  
  
  export default PrivateRoute