import { Container, Card, CardContent, Typography, TextField, Button, FormControl, Select, InputLabel, MenuItem, FormHelperText, Grid, IconButton } from '@mui/material'
import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { db } from '../Auth/firebase'
import { collection, doc, addDoc, updateDoc, getDoc } from "firebase/firestore";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useParams } from 'react-router-dom';
import Header from '../Layout/Header';

function AlertForms({currentUser}) {
    console.log(currentUser.uid)
    const params = useParams();
    const navigate = useNavigate();
    const [ type, setType ] = useState('price')
    const [ title, setTitle ] = useState('')
    const [ url, setUrl ] = useState('')
    const [ period, setPeriod ] = useState(24);

    useEffect(async () => {
        if(params && params.alertid !== "add") {
            getDoc(doc(db, `alerts/${params.alertid}`)).then(alertRef => {
                if(alertRef.exists()) {
                    setType(alertRef.get('type') ? alertRef.get('type') : 'price')
                    setTitle(alertRef.get('title'))
                    setUrl(alertRef.get('url'))
                    setPeriod(alertRef.get('period') ? alertRef.get('period') : 24)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }, [])

    const handleAddAlert =  async () => {
        if(params && params.alertid !== "add") {
            const updatedDoc = {
                title: title,
                url: url,
                period: period,
                updateDate: new Date()
            }
            await updateDoc(doc(db, `alerts/${params.alertid}`), updatedDoc)
        } else {
            await addDoc(collection(db, `alerts`), {
                owner: currentUser.uid,
                title: title,
                url: url,
                status: 'initial',
                period: period,
                date: new Date()
            })
        }
        navigate('/dashboard')
    }

    return (
        <>
            <Header />
            <Container sx={{ mt: 3 }} maxWidth={'md'}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                <IconButton aria-label="back" onClick={() => navigate('/dashboard')} >
                                    <ArrowCircleLeftOutlinedIcon sx={{ fontSize: 40 }} color="secondary" />
                                </IconButton>
                                <Typography variant="h4" style={{display: 'flex', alignItems: 'center'}}>{params.alertid === "add" ? "Add new alert" : "Update alert"}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" fullWidth margin="dense" value={type} onChange={(e) => setPeriod(e.target.value)} label="Age">
                                        <MenuItem value='price'>Price</MenuItem>
                                        <MenuItem disabled value=''>Ping&nbsp;<small>(coming soon)</small></MenuItem>
                                        <MenuItem disabled value=''>Content&nbsp;<small>(coming soon)</small></MenuItem>
                                    </Select>
                                    <FormHelperText>{type === "price" ? (
                                        "Be warned each time product price changes"
                                    ) : null}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Periodicity</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" fullWidth margin="dense" value={period} onChange={(e) => setPeriod(e.target.value)} label="Age">
                                        <MenuItem value={24}>Every day</MenuItem>
                                        <MenuItem value={12}>Twice a day</MenuItem>
                                        <MenuItem value={1}>Every Hour</MenuItem>
                                    </Select>
                                    <FormHelperText>Set periodicity of checks</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField id="title" value={title} label="Alert title" fullWidth margin="dense" onChange={(e) => setTitle(e.target.value)} />
                                    <FormHelperText sx={{m: 0}}>Give name to your alert</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField id="url" value={url} label="Url" fullWidth margin="dense" onChange={(e) => setUrl(e.target.value)} />
                                    <FormHelperText sx={{m: 0}}>Paste product your want to check</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} mt={1}>
                                <Button fullWidth variant="outlined" color="secondary" onClick={handleAddAlert}>
                                    {params.alertid === "add" ? "Add alert" : "Update alert"}
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </>
    )
}

export default AlertForms